export const myMixin = {
  methods: {
    route(maps, from, to, line = true) {
      let style = "solid",
        width = 6,
        routeItem = [...from, to];
      if (!line) {
        style = "dot";
        width = 4;
        routeItem = [from, ...to];
      }
      let route = new maps.multiRouter.MultiRoute(
        {
          referencePoints: routeItem,
        },
        {
          balloonLayout: "",
          balloonPanelMaxMapArea: 0,
          wayPointVisible: false,
          boundsAutoApply: false,
          routeActiveStrokeWidth: width,
          routeActiveStrokeStyle: style,
          routeActiveStrokeColor: "#000",
          routeStrokeWidth: 0,
        }
      );
      this.map.geoObjects.add(route);
    },

    routeIcon(maps, value) {
      let pointsBalloon = [],
        icon,
        size,
        offer;
      value.forEach((el, i) => {
        switch (i) {
          case this.location:
            icon = "../end-point.png";
            size = [32, 32];
            offer = [-16, -16];
            break;
          case 0:
            icon = "../start-point.png";
            size = [20, 20];
            offer = [-10, -10];
            break;
          default:
            (icon = "../point.png"), (size = [20, 20]), (offer = [-10, -10]);
            break;
        }
        pointsBalloon[i] = new maps.Placemark(
          el,
          {
            balloonContent: `
              <div class="map-info">
                <div class="map-info__name flex" style="display: ${i === this.location ? "flex" : "none"
              };">
                  <div class="map-info__name-box">
                    <h2 class="map-info__name-title">Инфо о заказе</h2>
                  </div>
                  <button class="map-info__online-btn"">Онлайн</button>
                </div>
                <ul class="map-info__number">
                  <li class="map-info__number-item flex">
                    <p class="map-info__number-text">${this.routeListFull[i].name.length > 4 &&
                this.routeListFull[i].name.slice(0, 4) === "ANG-"
                ? "Номер заказа"
                : "Адрес доставки"
              }</p>
                    <p class="map-info__number-value">${this.routeListFull[i].name ?? "-"}</p>
                  </li>
                  <li class="map-info__number-item flex">
                    <p class="map-info__number-text">Филиал</p>
                    <p class="map-info__number-value">${this.routeListFull[i]?.employee?.name ?? "-"
              }</p>
                  </li>
                  <li class="map-info__number-item flex">
                    <p class="map-info__number-text">Прогнозная дата прибытия</p>
                    <p class="map-info__number-value">${this.routeListFull[i]?.eta ?? "-"}</p>
                  </li>
                  <li class="map-info__number-item flex">
                    <p class="map-info__number-text">Контрагент</p>
                    <p class="map-info__number-value">${this.routeListFull[i].filial ?? "-"}</p>
                  </li>
                  <li class="map-info__number-item flex">
                    <p class="map-info__number-text">Фактическая дата прибытия</p>
                    <p class="map-info__number-value">${this.routeListFull[i].come_date ?? "-"}</p>
                  </li>
                  <li class="map-info__number-item flex" style="display: ${i === this.location ? "flex" : "none"
              };">
                    <p class="map-info__number-text">Статус машины</p>
                    <p class="map-info__number-value">${this.statusChange(
                this.routeListFull[i].status
              )}</p>
                  </li>
                  <li class="map-info__number-item flex" style="display: ${i === this.location && this.$route.name === "Route" ? "flex" : "none"
              };">
                    <p class="map-info__number-text">Местоположение</p>
                    <p class="map-info__number-value">${this.routeListFull[i].from ?? "-"}</p>
                  </li>
                  <li class="map-info__number-item flex">
                    <p class="map-info__number-text">Остаток груза</p>
                    <p class="map-info__number-value">${this.routeListFull[i].weight ?? "-"}</p>
                  </li>
                </ul>
              </div>`,
            hintContent: '<div class="map__hint">hover</div>',
            iconContent: `<div style="font-size: 10px; position: absolute; top: 100%; left: 100%; transform: translate(-60%, -40%);"><p>${i !== 0 ? i : ""
              }</p></div>`,
          },
          // <li class="map-info__number-item flex" style="display: ${
          //   this.routeListFull[i].status === "finished" ? "flex" : "none"
          // }">
          //   <p class="map-info__number-text">Фактическая дата и время прибытия</p>
          //   <p class="map-info__number-value">${this.routeListFull[i].date ?? "-"}</p>
          // </li>
          {
            iconLayout: "default#imageWithContent",
            iconImageHref: icon,
            iconImageSize: size,
            iconImageOffset: offer,
          }
        );
      });
      pointsBalloon.forEach((el, i) => {
        el.events.add("click", () => {
          this.$emit("point-click", this.routeListFull[i].id);
        });
        this.map.geoObjects.add(el);
      });
    },

    pointsIcon(maps, value) {
      if (value[0][0]) {
        let a = new maps.Placemark(
          value[0],
          {},
          {
            iconLayout: "default#image",
            iconImageHref: "../point.png",
            iconImageSize: [20, 20],
            iconImageOffset: [-10, -10],
          }
        );
        this.map.geoObjects.add(a);
      }
      if (value[1][0]) {
        let a = new maps.Placemark(
          value[1],
          {},
          {
            iconLayout: "default#image",
            iconImageHref: "../point.png",
            iconImageSize: [20, 20],
            iconImageOffset: [-10, -10],
          }
        );
        this.map.geoObjects.add(a);
      }
    },
    statusChange(value) {
      switch (value) {
        case "send":
        case "Отгружен":
          return "Отгружен";
        case "in_process":
          return "В пути";
        case "accepted":
          return "Принят";
        case "stopped":
          return "Остановлен";
        case "finished":
          return "Завершен";
        default:
          return (value[0].toUpperCase() + value.slice(1)).replace(/_/g, " ");
      }
    },
    // clusterer(maps, value) {
    //   let cluster = new maps.Clusterer({
    //     clusterIconLayout: "default#image",
    //     clusterIconImageHref: "../point.png",
    //     clusterIconImageSize: [30, 30],
    //     clusterIconOffset: [-4, 25],
    //     clusterDisableClickZoom: true,
    //   });
    //   this.map.geoObjects.add(cluster.add(value));
    // },
  },
};
