<template>
  <div class="map-block">
    <yandex-map
      ref="map"
      class="map"
      :coords="coords"
      :zoom="7"
      :cluster-options="clusterOptions"
      :settings="settings"
      @click="mapClicked($event)"
      @map-was-initialized="onInitMap"
    >
      <ymap-marker :coords="coords" marker-id="123" hint-content="some hint" />
    </yandex-map>
  </div>
</template>

<script>
const settings = {
  // apiKey: "5e34fc66-a948-4e55-837c-1253cd994b2f",
  apiKey: process.env.VUE_APP_YMAPS_APIKEY,
  lang: "ru_RU",
};
import {loadYmap, yandexMap, ymapMarker} from "vue-yandex-maps";
import axios from "axios";
import { myMixin } from "./map-function.js";

export default {
  name: "HomeMap",
  components: {
    yandexMap,
    ymapMarker,
  },
  mixins: [myMixin],
  props: {
    searchClick: {
      type: Array,
      default: () => [],
    },
    getCoords: {
      type: Array,
      default: () => [],
    },
    cityDeliveryZones: Array
  },
  data() {
    return {
      coords: this.getCoords,
      settings,
      clusterOptions: {
        1: {
          clusterDisableClickZoom: false,
          clusterOpenBalloonOnClick: false,
          clusterBalloonLayout: [
            "<ul class=list>",
            "{% for geoObject in pointsData %}",
            '<li><a href=# class="list_item">{{ geoObject.properties.balloonContentHeader|raw }}</a></li>',
            "{% endfor %}",
            "</ul>",
          ].join(""),
        },
      },
      map: null,
      ymaps: null,
      cityDeliveryZoneCollection: null
    };
  },
  watch: {
    searchClick() {
      this.coords = [+this.searchClick[1], +this.searchClick[0]];
      this.$emit("coords", [+this.searchClick[1], +this.searchClick[0]]);
    },
  },
  async mounted() {

    await loadYmap({ ...settings, debug: true });
    this.ymaps = window.ymaps;

    this.cityDeliveryZoneCollection = new this.ymaps.GeoObjectCollection(null, {

    });
    this.addCityDeliveryZone();

  },
  methods: {
    async mapClicked(e) {
      this.coords = e.get("coords");
      this.$emit("coords", this.coords);
      await axios
        .get(
          `https://geocode-maps.yandex.ru/1.x/?apikey=c2ed0336-3aa7-4167-9030-608c1ecbf17c&format=json&geocode=${this.coords[1]},${this.coords[0]}&lang=ru_RU`
        )
        .then((response) => {
          this.$emit(
            "address",
            response.data.response.GeoObjectCollection.featureMember[0].GeoObject.name
          );
        });
    },
    onInitMap(map){
      this.map = map;
    },
    addCityDeliveryZone() {
      this.cityDeliveryZones.forEach((el) => {
        this.coordinates = el.coordinates

        var myGeoObject = new this.ymaps.GeoObject({
          // Описываем геометрию геообъекта.
          geometry: {
            // Тип геометрии - "Многоугольник".
            type: "Polygon",
            // Указываем координаты вершин многоугольника.
            coordinates: [
              // Координаты вершин внешнего контура.
              this.coordinates
            ],
            // Задаем правило заливки внутренних контуров по алгоритму "nonZero".
            fillRule: "nonZero"
          },
          // Описываем свойства геообъекта.
          properties:{
            // Содержимое балуна.
            balloonContent: el.properties.description
          }
        }, {
          // Описываем опции геообъекта.
          // Цвет заливки.
          fillColor: '#00FF00',
          // Цвет обводки.
          strokeColor: '#0000FF',
          // Общая прозрачность (как для заливки, так и для обводки).
          opacity: 0.5,
          openBalloonOnClick: false,
          // Ширина обводки.
          strokeWidth: 5,
          // Стиль обводки.
          strokeStyle: 'shortdash'
        });

        this.cityDeliveryZoneCollection.add(myGeoObject)
      })

      // Добавляем многоугольник на карту.
      this.map.geoObjects.add(this.cityDeliveryZoneCollection);
    },
  },
};
</script>

<style scoped>
.map-block {
  max-width: 100%;
  max-height: 100%;
  position: relative;
}
.map {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 579px) {
  .map-block {
    width: 100%;
  }

  .map {
    height: calc(100% - 50px);
  }
}
</style>
